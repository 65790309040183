const nginxStatusCodes = [404, 413];

export function preprocessResponse(response: Response) {
  if (response.status === 204) {
    return Promise.resolve({});
  }

  const contentType = response.headers.get('content-type');
  const type =
    contentType && contentType.indexOf('application/json') !== -1
      ? 'json'
      : 'text';

  if (response.ok) return response[type]();

  if (nginxStatusCodes.indexOf(response.status) !== -1) {
    return response
      .text()
      .then(() => Promise.reject({ code: response.status }));
  }

  return response[type]().then(
    body =>
      Promise.reject({
        code: response.status,
        status: response.statusText,
        body,
      }),
    err =>
      Promise.reject({
        code: response.status,
        status: response.statusText,
        body: err,
      }),
  );
}
