import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

export { STATE_NAME } from './constants';

export const LoadableAlertbar = loadable(() =>
  autoRetry(() =>
    import(/* webpackChunkName: "Alertbar" */ './containers/AlertBar'),
  ),
);

export { alertsReducer } from './reducer';
