import React from 'react';

import { EventNote } from '@mui/icons-material';

import { makeStyles } from 'tss-react/mui';

import { Grid, Link, units } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { useIntl } from 'ha/i18n';
import { countryToFlag } from 'ha/utils/countryToFlag';

import { ContentfulImg } from 'ha/modules/Contentful/components/ContentfulImg';

import { Size } from '../constants';
import { FeaturedLocation } from '../types';

const useStyles = makeStyles<{ size: Size }>()((theme, { size }) => ({
  container: {
    overflow: 'hidden',
    width: units.pxToRem(120),

    [theme.breakpoints.up('sm')]: {
      width: units.pxToRem(size === Size.Small ? 146 : 222),
    },
  },
  imageContainer: {
    borderRadius: units.pxToRem(8),
    overflow: 'hidden',
    background: theme.palette.grey[300],
    height: units.pxToRem(80),

    [theme.breakpoints.up('sm')]: {
      height: units.pxToRem(size === Size.Large ? 140 : 80),
    },
  },
  title: {
    fontFamily: interFont.fontFamily,
    fontSize: units.pxToRem(18),
    lineHeight: 1.55,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    letterSpacing: units.pxToRem(-0.2),
  },
  articles: {
    fontFamily: interFont.fontFamily,
    fontSize: units.pxToRem(14),
    lineHeight: 1.71,
    fontWeight: 400,
    color: theme.palette.secondary.light,
  },
}));

interface Props {
  location: FeaturedLocation;
  size?: Size;
}

const LocationCard: React.FC<Props> = ({
  location: { name, pathname, code, imageUrl, articlesCount },
  size = Size.Large,
}) => {
  const { classes } = useStyles({ size });
  const { T } = useIntl();

  const withFlagIcon = !!code;

  return (
    <Link to={pathname}>
      <Grid
        container
        direction="column"
        rowGap={units.pxToRem(12)}
        className={classes.container}
        data-test-locator="LocationCard/Container"
      >
        <Grid
          item
          className={classes.imageContainer}
          data-test-locator="LocationCard/ImageContainer"
        >
          <ContentfulImg alt={name} src={imageUrl} />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ sm: 1 }}
                className={classes.title}
              >
                {withFlagIcon && <Grid item>{countryToFlag(code)}</Grid>}
                <Grid item>{T(name)}</Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.articles}>
              <Grid
                container
                alignItems="center"
                columnGap={units.pxToRem(4)}
                className={classes.articles}
              >
                {!withFlagIcon && (
                  <EventNote
                    color="inherit"
                    fontSize="small"
                    data-test-locator="LocationCard/ArticlesIcon"
                  />
                )}
                <Grid item>{T('blog.%s_articles', articlesCount)}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

export { LocationCard };
