import type { Event, ErrorEvent, EventHint } from '@sentry/types';

/**
 * This list contains error patterns to be matched against captured errors.
 *
 * IMPORTANT: When adding new patterns to this list, ensure that any special characters
 * (e.g., `.`, `*`, `+`, `?`, `(`, `)`, `\`, etc.) are properly escaped. Special characters
 * should be escaped using a backslash (`\`) to avoid unintended behavior
 * in the regular expression. For example, use `\.` to match a literal dot, or `\(.*\)` to
 * match any text within parentheses.
 *
 * @example
 * // A correct regex pattern to match "undefined is not an object (evaluating 't.Domain')"
 * /undefined is not an object \(evaluating 't\.Domain'\)/
 *
 * @example
 * // Matching URLs
 * /https:\/\/example\.com\/path/
 *
 */
export const filteredIssuesRegex: RegExp[] = [
  // Browser Issues
  /Failed to register a ServiceWorker for scope/,
  /Rejected/,
  /Cannot get system timezone/,
  /loadHermesData failed/,
  /^Loading chunk \d+ failed\./,
  /Router Error Boundary/,
  /Load search results failed/,
  /Object captured as promise rejection with keys: message, name, status/,
  /undefined is not an object \(evaluating 'n\.type'\)/,
  /A network error occurred\./,
  /Event `ProgressEvent` \(type=error\) captured as promise rejection/,
  /Script https:\/\/housinganywhere\.com\/ha-sw-precache\.js load failed/,
  /424 - : \/api\/v2\/user\/me\/realtime/,
  /NetworkError: A network error occurred\./,
  /null is not an object \(evaluating 'document\.getElementById\('non-printable'\)\.style'\)/,
  /Object captured as promise rejection with keys: message, name, transporterStackTrace/,
  /Dropzone already attached\./,
  /Failed check if user is qualified/,
  /User unable to complete payment/,
  /searchCity api error/,
  /Cannot read properties of undefined \(reading 'getBoundingClientRect'\)/,
  /Error getting the supported countries/,
  /undefined is not an object \(evaluating 't\.Domain'\)/,
  /undefined is not an object \(evaluating 'r\.DomainData'\)/,
  /null is not an object \(evaluating 'this\.selector\.appendChild'\)/,
  /ResizeObserver loop limit exceeded/,

  // Server Issues
  /loadListing failed/,
  /^query\(\) call aborted: GET/,
  /Failed to load cities/,
  /apiV2 tenant reviews overall request failed/,
];

export const ignoredIssuesRegex: RegExp[] = [
  /**
   * Error triggered when ResizeObserver detects an infinite loop in element resizing causing browser slowdown,
   * but eventually it settles and doesn't cause the app to crash. So its safe to completely ignore this error.
   * source - https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
   */
  /ResizeObserver loop completed with undelivered notifications./,
  /**
   * Error triggered in OTEL on some browsers - cause as of yet unknown.
   * Doesn't lead to any application issues so it's not necessary to track it.
   */
  /e\._flushOneBatch\(\)\.finally is not a function\./,
];

export const sampleCommonErrors =
  (sampleCommonErrorsRate: number) =>
  (event: ErrorEvent, hint: EventHint): Event | null => {
    const error = hint.originalException;

    if (
      error instanceof Error &&
      ignoredIssuesRegex.some(IssueRegex => IssueRegex.test(error.message))
    ) {
      return null;
    }

    if (
      error instanceof Error &&
      filteredIssuesRegex.some(IssueRegex => IssueRegex.test(error.message))
    ) {
      return Math.random() < sampleCommonErrorsRate ? event : null;
    }

    return event;
  };
