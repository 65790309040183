import React from 'react';

import {
  Markdown as MarkdownRender,
  Props,
} from 'ha/ui.legacy/Markdown/Markdown';

import { MarkdownBlockquote } from './MarkdownBlockquote';
import { MarkdownCode } from './MarkdownCode';
import { MarkdownH1 } from './MarkdownH1';
import { MarkdownH2 } from './MarkdownH2';
import { MarkdownH3 } from './MarkdownH3';
import { MarkdownImage } from './MarkdownImage';
import { MarkdownLi } from './MarkdownLi';
import { MarkdownLink } from './MarkdownLink';
import { MarkdownP } from './MarkdownP';
import { MarkdownUl } from './MarkdownUl';

const defaultComponents = {
  h1: MarkdownH1,
  h2: MarkdownH2,
  h3: MarkdownH3,
  p: MarkdownP,
  a: MarkdownLink,
  ul: MarkdownUl,
  li: MarkdownLi,
  img: MarkdownImage,
  code: MarkdownCode,
  blockquote: MarkdownBlockquote,
};

const Markdown: React.FC<Props> = ({
  content,
  components = {},
  className,
  ...props
}) => (
  <MarkdownRender
    content={content}
    components={{ ...defaultComponents, ...components }}
    className={className}
    {...props}
  />
);

export { Markdown };

// eslint-disable-next-line import/no-default-export
export default Markdown;
