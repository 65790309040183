enum UnitTypeVisibility {
  UnitTypeVisibilityAll = 0,
  UnitTypeVisibilityExclusiveOnly = 1,
}

export enum BrandingType {
  UniversitySignupPage = 'university_signup_page',
  CoBranded = 'co_branded',
}

export enum PartnershipType {
  PartneredWith = 'partnered_with',
  PoweredBy = 'powered_by',
}

export interface UniversityInfo {
  name: string;
  website: string;
  contactEmail: string;
  phone: string;
  iconUrl: string;
  footerImageUrl?: string;
  headerImageUrl?: string;
  description: string;
  country_code: string;
  category: string;
  unitTypeVisibility: UnitTypeVisibility;
  brandingType: BrandingType;
  partnershipType: PartnershipType;
  exclusiveAdvertiserIds: number[];
  defaultFilterValue: string;
  cityGeonameId?: number;
  lat?: number;
  lng?: number;
}
