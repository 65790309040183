import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

export const LoadableVerifyEmailDialogRoot = loadable(() =>
  autoRetry(
    () =>
      import(
        /* webpackChunkName: "VerifyEmailDialogRoot" */ './VerifyEmailDialogRoot'
      ),
  ),
);
