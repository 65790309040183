const scriptjsWrapper = (url: string) =>
  import(/* webpackChunkName: "scriptjs" */ 'scriptjs')
    .then(scriptjs => scriptjs.default)
    .then(
      scriptjs =>
        new Promise<void>(resolve => {
          scriptjs(url, resolve);
        }),
    );
export { scriptjsWrapper };
