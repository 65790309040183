import {
  alias,
  getAnonymousId,
  identify,
  IdentifyTraits,
  resetAnonymousId,
  setAnonymousId,
  track,
} from '@hbf/analytics';

import {
  getUserId,
  getIsAliased,
  getUserIdAlias,
  getUserTraits,
  getAnalyticsUserData,
  getUserUUID,
} from 'ha/modules/AuthLogic/selectors';
import { getFeatureFlags } from 'ha/modules/FeatureFlags';
import { Thunk } from 'ha/myredux/types';
import { CookieConsent } from 'ha/services/analytics/types';

import { EMPTY_USER_ID } from './constants';
import {
  identifyAnonymously,
  identifyRockerbox,
  identifyWithRockerbox,
} from './utils';

export const identifyUser =
  (
    {
      isCookieConsentEnabled,
      isAnonymousTrackingEnabled,
      hasUserRejectedAllCategories,
    }: CookieConsent,
    userTraits: IdentifyTraits,
  ): Thunk =>
  async (_, getState, { analytics }) => {
    const state = getState();

    const userId = getUserId(state);

    const { analyticsIdentityConflictResetMode } = getFeatureFlags(state);

    const isAnonymousTracking =
      isCookieConsentEnabled && isAnonymousTrackingEnabled;

    if (isAnonymousTracking) {
      resetAnonymousId(hasUserRejectedAllCategories);
    }

    if (!userId) {
      // Needs to call identify, to set new anonymous id on device mode destinations
      // if userId is not presented, anonymousId will be used instead
      identify(EMPTY_USER_ID, userTraits);
      analytics.userIdentificationComplete();
      return;
    }

    const userIdAlias = getUserIdAlias(state);
    const anonymousId = getAnonymousId()!;

    console.debug('analytics', 'identify', 'user id and anonymous id', {
      userIdAlias,
      anonymousId,
    });

    if (anonymousId !== userIdAlias) {
      if (anonymousId?.startsWith('u')) {
        // anonymous id is hardcoded to user id alias, however it is a different user id than current user.
        // => track and handle this case by resetting anonymous id
        console.debug(
          'analytics',
          'identify',
          `irregular anonymous id found: "${userIdAlias}"`,
        );

        track('DEBUG', {
          severity: 'ERROR',
          code: 'ANALYTICS_IDENTITY_INVALID_ANONYMOUS_ID',
          message:
            'Analytics: identify called with anonymous id being a different user alias',
          data: {
            userIdAlias,
            anonymousId,
          },
        });

        setAnonymousId(userIdAlias);
      } else {
        // anonymous id is still anonymous and is not yet hardcoded to user id alias.
        // => hardcode anonymous id to user id alias.
        // This should happen only on first page load after login or impersonation. Subsequent page loads should have equal anonymous id and user id alias.
        // RudderStack' "Mixpanel cloud mode compatibility" transform would use the hardcoded anonymous id to skip identity merge for cases like impersonation and multiple users using same browser.

        console.debug(
          'analytics',
          'identify',
          `setting anonymous id to user id alias "${userIdAlias}", previously "${anonymousId}"`,
        );

        // alias anonymous id to user id => triggers merge without error
        if (analyticsIdentityConflictResetMode === 'alias-flip-arguments') {
          alias(userIdAlias, anonymousId);
        } else {
          alias(anonymousId, userIdAlias);
        }

        // set anonymous id to user id => would not trigger merge moving forward
        setAnonymousId(userIdAlias);
      }
    }

    const userTraitsBaseline = getUserTraits(state);
    const userDataAnalytics = getAnalyticsUserData(state);
    const identifyPayload: IdentifyTraits = {
      ...userTraitsBaseline,
      ...userDataAnalytics,
      ...userTraits,
    };
    const userUUID = getUserUUID(state);
    const isAliased = getIsAliased(state);

    if (isAnonymousTracking && hasUserRejectedAllCategories) {
      // authenticated user id with anonymous tracking

      identifyRockerbox(userUUID, identifyPayload);

      identifyAnonymously(identifyPayload);

      analytics.userIdentificationComplete();
      return;
    }

    if (!isAliased) {
      // user is not aliased yet so we alias it through mixpanel and update the user's profile to reflect this
      if (analyticsIdentityConflictResetMode !== 'skip-legacy-alias') {
        alias(userIdAlias);
      }
    }

    identifyWithRockerbox(userUUID, identifyPayload, userIdAlias);

    analytics.userIdentificationComplete();
  };
