import { LoaderFunction, redirect } from 'react-router-dom';

import { Store } from 'redux';

import { HttpStatus } from '@ha/api/v2/handleApiV2Response';

import { IntlObject } from 'ha/i18n';
import {
  getIsAuthenticated,
  getIsUnderReview,
} from 'ha/modules/AuthLogic/selectors';

export const createMyPagesGuardLoader =
  ({ store, intl }: { store: Store; intl: IntlObject }): LoaderFunction =>
  ({ request }) => {
    if (!getIsAuthenticated(store.getState())) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw redirect(
        intl.urlResolver.getOAuthSigninUrl(request.url),
        HttpStatus.FOUND_REDIRECTION,
      );
    }

    if (getIsUnderReview(store.getState())) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw redirect('/under-review', HttpStatus.FOUND_REDIRECTION);
    }

    return null;
  };
