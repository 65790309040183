import React from 'react';

import { parse } from 'url';

import { Link } from '@hbf/dsl/legacy';

import { isInstagramPost } from 'ha/utils/thirdParty/isInstagramPost';
import { isPlotlyUrl, isInfogramUrl } from 'ha/utils/thirdParty/plots';
import { isSpotifyEpisode } from 'ha/utils/thirdParty/spotify';
import { isYoutubeVideo } from 'ha/utils/thirdParty/youtubeUrl';
import { fixHref } from 'ha/utils/urls/fixHref';
import { isExternalUrl } from 'ha/utils/urls/isExternalUrl';
import { isInternalWithSubdomain } from 'ha/utils/urls/isInternalWithSubdomain';

import { Tableau, isTableauUrl } from 'ha/components/Tableau';
import { InstagramCard } from 'ha/components.legacy/InstagramCard';
import { Plot as PlotCard } from 'ha/components.legacy/Plot';
import { YoutubeCard } from 'ha/components.legacy/YoutubeCard';

import { isDatawrapperUrl, Datawrapper } from '../Datawrapper';
import { SpotifyCard } from '../SpotifyCard';

interface Props {
  children: React.ReactNode;
  href: string;
}

// This is only for tenant, advertiser, country and city types
// If the embedded link needs to be added to press and landing types,
// you'll need to add it to src/ui.legacy/Markdown/MarkdownContent.js
const MarkdownLink: React.FC<Props> = ({ children, href }) => {
  const text = Array.isArray(children) ? children[0] : children;

  if (!text || text === href) {
    if (isYoutubeVideo(href)) {
      return <YoutubeCard src={href} />;
    }
    if (isInstagramPost(href)) {
      return <InstagramCard src={href} />;
    }
    if (isTableauUrl(href)) {
      return <Tableau href={href} />;
    }
    if (isPlotlyUrl(href) || isInfogramUrl(href)) {
      return <PlotCard src={href} />;
    }
    if (isSpotifyEpisode(href)) {
      return <SpotifyCard src={href} />;
    }
    if (isDatawrapperUrl(href)) {
      return <Datawrapper src={href} />;
    }
  }

  if (href.includes('mailto:')) {
    return (
      <Link to={href} kind="mint-underline" target="_blank">
        {children}
      </Link>
    );
  }

  const fixedHref = fixHref(href);

  if (isExternalUrl(href) || isInternalWithSubdomain(href)) {
    return (
      <Link to={fixedHref} target="_blank" kind="mint-underline">
        {children}
      </Link>
    );
  }

  const parsedHref = parse(fixedHref);

  return (
    <Link
      to={`${parsedHref.pathname}${parsedHref.search || ''}`}
      kind="mint-underline"
      target="_blank"
    >
      {children}
    </Link>
  );
};

export { MarkdownLink };
