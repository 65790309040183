import { MakeCall } from './createMakeCall';

export type GetBillingInvoiceURLResponse = {
  url: string;
  expiresAt: number;
};

export const getBillingInvoiceURL = (makeCall: MakeCall) => (uuid: string) =>
  makeCall<GetBillingInvoiceURLResponse>(
    'GET',
    `/api/v2/billing/invoice-url?uuid=${uuid}`,
  );
