import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/sv';

import { Language, Locale } from '@ha/intl';
import type { Logger } from '@hbf/log';

import { Thunk } from 'ha/myredux/types';

import { clearUserData } from '../clearUserData';
import { IMPERSONATION_STATE_QUERY_PARAMETER } from '../constants';
import { getUser, getUserIdAlias } from '../selectors';

import { fetchUser } from './fetchUser';

export function initSession({
  lang,
  logger,
  referrer,
  withAccessToken = false,
}: {
  lang: Language;
  logger?: Logger;
  referrer?: string;
  withAccessToken?: boolean;
}): Thunk {
  return async (dispatch, getState, { cookie, analytics }) => {
    const localeFromLanguage = Locale[lang];

    dayjs.locale(localeFromLanguage);

    if (!process.browser) {
      if (withAccessToken) {
        // fetches user information, also fetches impersonation information
        await dispatch(fetchUser(logger, referrer));
      }

      return;
    }

    const state = getState();

    const user = getUser(state);

    const urlObject = new URL(window.location.href);

    if (urlObject.searchParams.get(IMPERSONATION_STATE_QUERY_PARAMETER)) {
      urlObject.searchParams.delete(IMPERSONATION_STATE_QUERY_PARAMETER);

      analytics.onReady(() => {
        clearUserData(cookie);

        window.location.assign(urlObject.toString());
      });

      return;
    }

    // setup hotjar

    window.hj?.('identify', user ? getUserIdAlias(state) : null, {});

    // setup unless

    if (window.Txt) {
      const userType = user ? user?.type || 'tenant' : 'anonymous';

      const userData = [
        {
          key: 'userType',
          value: userType,
          type: 'string',
          custom: true,
        },
      ];

      if (user) {
        window.Txt.identify(
          `${user.uuid}@user.housinganywhere.com`,
          userData,
          user.uuid,
        );
      } else {
        window.Txt.updateProfile(userData);
      }
    }
  };
}
