import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { TopicCluster, isContentfulSupported } from '@ha/contentful';

import { useIntl } from 'ha/i18n';

import { LanguageSwitcher } from '../LanguageSwitcher/components/LanguageSwitcher';

import { BaseNavigationFooter } from './BaseNavigationFooter';
import {
  NO_FOLLOW_REL,
  NavigationFooterLinks,
  SocialLinks,
} from './BaseNavigationFooterLinks';
import { HousingAnywhereLogo } from './components/NavigationLogo/NavigationLogo';

const useStyles = makeStyles()(theme => ({
  logo: {
    fill: theme.palette.secondary.main,
  },
}));

export const FooterHousingAnywhereLinks = () => {
  const { T, urlResolver, lang } = useIntl();
  const isSupportedLanguage = isContentfulSupported(lang);

  return (
    <NavigationFooterLinks title={T('nav.footer.ha')}>
      <NavigationFooterLinks.Link name="About" href={urlResolver.getAboutUrl()}>
        {T('nav.footer.about')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Careers"
        href={urlResolver.getCareersUrl()}
      >
        {T('nav.footer.careers')}
      </NavigationFooterLinks.Link>
      {isSupportedLanguage && (
        <NavigationFooterLinks.Link
          name="Press"
          href={urlResolver.getPressUrl()}
        >
          {T('nav.footer.press')}
        </NavigationFooterLinks.Link>
      )}

      <NavigationFooterLinks.Link
        name="Become a partner plans"
        href={urlResolver.getPartnershipsUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.partners')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Terms and conditions"
        href={urlResolver.getTermsUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.terms')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Privacy Policy"
        href={urlResolver.getPrivacyPolicyUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.privacy')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Cookie Policy"
        href={urlResolver.getCookiePolicyUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.cookie')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Sitemap"
        href={urlResolver.getSitemapUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.sitemap')}
      </NavigationFooterLinks.Link>
    </NavigationFooterLinks>
  );
};

export const FooterTenantLinks = () => {
  const { T, urlResolver } = useIntl();

  return (
    <NavigationFooterLinks title={T('nav.footer.tenants')}>
      <NavigationFooterLinks.Link
        name="How to rent"
        href={urlResolver.getRenting()}
      >
        {T('nav.footer.how_it_works')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Pricing tenants"
        href={urlResolver.getPricingTenantsUrl()}
      >
        {T('nav.footer.pricing')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Payments tenants"
        href={urlResolver.getPaymentsTenant()}
      >
        {T('nav.footer.pay_rent')}
      </NavigationFooterLinks.Link>

      <NavigationFooterLinks.Link
        name="Tenants blog"
        href={urlResolver.getTenantsGlobalBlogUrl()}
      >
        {T('nav.footer.tenants_blog')}
      </NavigationFooterLinks.Link>
    </NavigationFooterLinks>
  );
};

export const FooterSupportLinks = () => {
  const { T, urlResolver } = useIntl();

  return (
    <NavigationFooterLinks title={T('nav.footer.support')}>
      <NavigationFooterLinks.Link
        name="Help Center"
        href={urlResolver.getAnswersUrl()}
      >
        {T('nav.footer.help')}
      </NavigationFooterLinks.Link>

      <NavigationFooterLinks.Link
        name="Contact us"
        href={urlResolver.getContactUrl()}
      >
        {T('nav.footer.contact')}
      </NavigationFooterLinks.Link>
    </NavigationFooterLinks>
  );
};

export const FooterAdvertiserLinks = () => {
  const { T, urlResolver, lang } = useIntl();

  return (
    <NavigationFooterLinks title={T('nav.footer.landlords')}>
      <NavigationFooterLinks.Link
        name="How to rent out"
        href={urlResolver.getRentingOut()}
      >
        {T('nav.footer.how_it_works')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Pricing landlords"
        href={urlResolver.getPricingLandlordsUrl()}
      >
        {T('nav.footer.pricing')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Become an advertiser"
        href={urlResolver.getPrivateLandlordLandingPageUrl()}
      >
        {T('nav.footer.become_landlord')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Payments landlords"
        href={urlResolver.getPaymentsLandlord()}
      >
        {T('nav.footer.collect_rent')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Help Center"
        href={urlResolver.getHelpCenterUrl(lang)}
      >
        {T('nav.footer.how_to_guides')}
      </NavigationFooterLinks.Link>
      {urlResolver.hasLandlordsBlogUrl(lang) && (
        <React.Fragment>
          <NavigationFooterLinks.Link
            name="Success stories"
            href={urlResolver.getLandlordsBlogUrl(
              lang,
              TopicCluster.CaseStudies,
            )}
          >
            {T('nav.footer.success_stories')}
          </NavigationFooterLinks.Link>

          <NavigationFooterLinks.Link
            name="Blog for landlords"
            href={urlResolver.getLandlordsBlogUrl(lang)}
          >
            {T('nav.footer.landlord_blog')}
          </NavigationFooterLinks.Link>
        </React.Fragment>
      )}
      <NavigationFooterLinks.Link
        name="RentRadar"
        href={urlResolver.getRentRadarUrl()}
        rel={NO_FOLLOW_REL}
        target="_blank"
      >
        {T('nav.footer.rentradar')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Integrations"
        href={urlResolver.getIntegrationsUrl()}
      >
        {T('nav.footer.integrations')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Legal rental information"
        href={urlResolver.getLegalUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.sample_contracts')}
      </NavigationFooterLinks.Link>
    </NavigationFooterLinks>
  );
};

export const NavigationFooter = () => {
  const { classes } = useStyles();

  return (
    <BaseNavigationFooter
      slots={{
        startSection: (
          <React.Fragment>
            <div>
              <HousingAnywhereLogo
                size="full"
                color="secondary"
                className={classes.logo}
              />
            </div>

            <LanguageSwitcher size="full-secondary" />
          </React.Fragment>
        ),
        middleSection: (
          <React.Fragment>
            <BaseNavigationFooter.LinkSectionColumn>
              <FooterHousingAnywhereLinks />
            </BaseNavigationFooter.LinkSectionColumn>
            <BaseNavigationFooter.LinkSectionColumn>
              <FooterTenantLinks />
              <FooterSupportLinks />
            </BaseNavigationFooter.LinkSectionColumn>
            <BaseNavigationFooter.LinkSectionColumn>
              <FooterAdvertiserLinks />
            </BaseNavigationFooter.LinkSectionColumn>
          </React.Fragment>
        ),
        endSection: <SocialLinks />,
      }}
    />
  );
};
