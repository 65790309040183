import React from 'react';

import loadable from '@loadable/component';

import { makeStyles } from 'tss-react/mui';

import { BlogHomepageData } from '@ha/contentful';
import { Grid, Link, units } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';
import { autoRetry } from 'ha/utils/loadable';

import { ReadingTimeAndDate } from 'ha/modules/Blog/components/ReadingTimeAndDate';
import { getTopicClusterLabelById } from 'ha/modules/Blog/utils';
import { ContentfulImg } from 'ha/modules/Contentful/components/ContentfulImg';

import { Orientation, Size } from './constants';

export const LoadableArticleContent = loadable(
  () =>
    autoRetry(
      () => import(/* webpackChunkName: "PanoramaViewer" */ './ArticleContent'),
    ),
  {
    ssr: false,
  },
);

const useStyles = makeStyles<{ size: Size; orientation: Orientation }>()(
  (theme, { size, orientation }) => ({
    container: {
      overflow: 'hidden',
      columnGap: units.pxToRem(8),

      ...(size === Size.Small && {
        [theme.breakpoints.up('md')]: {
          columnGap: units.pxToRem(16),
        },
      }),

      ...(size === Size.Medium && {
        [theme.breakpoints.up('md')]: {
          columnGap: units.pxToRem(12),
        },
      }),

      ...(orientation === Orientation.Vertical && {
        flexDirection: 'column',
        rowGap: units.pxToRem(8),
        columnGap: 'unset',
        [theme.breakpoints.up('md')]: {
          rowGap: units.pxToRem(20),
        },
      }),
    },
    imageContainer: {
      borderRadius: units.pxToRem(8),
      overflow: 'hidden',
      width: units.pxToRem(118),
      height: units.pxToRem(112),
      background: theme.palette.grey[300],

      ...(size === Size.Small && {
        [theme.breakpoints.up('md')]: {
          width: units.pxToRem(142),
          height: units.pxToRem(142),
        },
      }),

      ...(size === Size.Medium && {
        [theme.breakpoints.up('md')]: {
          width: units.pxToRem(206),
          height: units.pxToRem(170),
        },
      }),

      ...(size === Size.Large && {
        width: '100%',
        height: units.pxToRem(240),

        [theme.breakpoints.up('md')]: {
          height: units.pxToRem(286),
        },
      }),
    },
    contentContainer: {
      ...(size === Size.Large && {
        [theme.breakpoints.up('md')]: {
          height: units.pxToRem(358),
          '& > div:first-of-type': {
            flexGrow: 1,
          },
        },
      }),
    },
    topicCluster: {
      fontFamily: interFont.fontFamily,
      fontSize: units.pxToRem(14),
      lineHeight: 1.71,
      fontWeight: 400,
      color: theme.palette.primary.dark,
      marginBottom: units.pxToRem(4),
    },
    title: {
      fontFamily: interFont.fontFamily,
      display: '-webkit-box',
      flexGrow: 1,
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      fontWeight: 600,
      fontSize: units.pxToRem(16),
      lineHeight: 1.625,
      color: theme.palette.secondary.main,
      marginBottom: units.pxToRem(4),

      [theme.breakpoints.up('md')]: {
        flexGrow: 'unset',
        fontSize: units.pxToRem(18),
        lineHeight: 1.33,
      },

      ...(size === Size.Small && {
        [theme.breakpoints.up('md')]: {
          WebkitLineClamp: 1,
        },
      }),

      ...(size === Size.Medium && {
        [theme.breakpoints.up('md')]: {
          WebkitLineClamp: 2,
        },
      }),

      ...(size === Size.Large && {
        marginBottom: units.pxToRem(8),
      }),
    },
    description: {
      fontFamily: interFont.fontFamily,
      display: 'block',
      fontSize: units.pxToRem(16),
      lineHeight: 1.625,
      fontWeight: 400,
      color: theme.palette.secondary.light,
      overflow: 'hidden',

      ...(size === Size.Small && {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      }),

      ...(size === Size.Medium && {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      }),

      ...(size === Size.Large && {
        maxHeight: units.pxToRem(156),
      }),
    },
    readingTimeAndDate: {
      fontFamily: interFont.fontFamily,
      fontSize: units.pxToRem(14),
      lineHeight: 1.29,
      fontWeight: 400,
      color: theme.palette.neutral?.[50],
      padding: units.pxToRem(2, 0),
    },
  }),
);

interface Props {
  article: BlogHomepageData;
  size?: Size;
  orientation?: Orientation;
}

const ArticleCard: React.FC<Props> = ({
  article: {
    topicCluster,
    title,
    description,
    content,
    updatedAt,
    pathname,
    headerImageAsset,
    readingTime,
  },
  size = Size.Small,
  orientation = Orientation.Horizontal,
}) => {
  const { classes } = useStyles({ size, orientation });
  const { T } = useIntl();
  const { md } = useMediaQuery();

  return (
    <Link to={pathname}>
      <Grid
        container
        wrap="nowrap"
        className={classes.container}
        data-test-locator="ArticleCard"
      >
        <Grid
          item
          flexShrink={0}
          className={classes.imageContainer}
          data-test-locator="ArticleCard/imageContainer"
        >
          <ContentfulImg imageAsset={headerImageAsset} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          data-test-locator="ArticleCard/contentContainer"
          className={classes.contentContainer}
        >
          <Grid container direction="column">
            {!!topicCluster && (
              <Grid
                item
                className={classes.topicCluster}
                data-test-locator="ArticleCard/topicCluster"
              >
                {T(getTopicClusterLabelById(topicCluster))}
              </Grid>
            )}
            <Grid item className={classes.title}>
              {title}
            </Grid>
            {md && <LoadableArticleContent content={content} />}
            {md && !content && (
              <Grid
                item
                className={classes.description}
                data-test-locator="ArticleCard/description"
              >
                {description}
              </Grid>
            )}
          </Grid>
          <ReadingTimeAndDate readingTime={readingTime} updatedAt={updatedAt} />
        </Grid>
      </Grid>
    </Link>
  );
};

export { ArticleCard };
