type Options = {
  retries?: number;
  interval?: number;
};

export function autoRetry<R>(
  fn: () => Promise<R>,
  { retries = 3, interval = 500 }: Options = {},
) {
  return new Promise<R>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error);
            return;
          }

          autoRetry(fn, {
            retries: retries - 1,
            interval,
          }).then(resolve, reject);
        }, interval);
      });
  });
}
