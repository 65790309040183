import { EventName } from './constants';

export { EventName };

const isUnlessInitialized = () => typeof window.Txt !== 'undefined';

export const trackAudience = (eventName: EventName) => {
  if (isUnlessInitialized()) {
    window.Txt.track({
      eventName,
    });
  }
};

/**
 * opts in to unless tracking. https://docs.unless.com/reference/opting-in
 */
export const optInTracking = () => {
  if (isUnlessInitialized()) {
    window.Txt.optInTracking();
  }
};

/**
 * opts out of unless tracking. https://docs.unless.com/reference/opting-out.
 *
 * Choosing 'essential' will still show experiences wherever possible without placing cookies. https://unless.com/en/help/audiences/cookieless-experiences/
 * Choosing 'none' will opt out of all experiences.
 *
 */
export const optOutTracking = (mode?: 'essential' | 'none') => {
  if (isUnlessInitialized()) {
    window.Txt.optOutTracking(mode);
  }
};
