import React from 'react';

import { getAppVersion } from '@ha/core/MobileApp';
import { Typography } from '@hbf/dsl/core';

export const HAAppMetadata = () => {
  const appVersion = getAppVersion();

  if (!appVersion) return null;

  return (
    <Typography
      textAlign="center"
      variant="caption/regular"
      data-ha-mobile="app-metadata"
    >
      {appVersion}
    </Typography>
  );
};
