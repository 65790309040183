import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/core';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7),
    justifyContent: 'space-between',
    background: theme.palette.mono.light,
    padding: theme.spacing(7, 5),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7),
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(9), // 20
    },
  },
  startSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(7),
  },
  middleSection: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(8),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  endSection: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  linkSectionColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    width: units.pxToRem(180),
  },
}));

const BaseNavigationFooterLinkSectionColumn = ({
  children,
}: React.PropsWithChildren) => {
  const { classes } = useStyles();

  return <div className={classes.linkSectionColumn}>{children}</div>;
};

interface Props {
  slots: {
    startSection: React.ReactNode;
    middleSection: React.ReactNode;
    endSection: React.ReactNode;
  };
}

export const BaseNavigationFooter = ({ slots }: Props) => {
  const { startSection, middleSection, endSection } = slots;
  const { classes } = useStyles();

  return (
    <div className={classes.container} data-test-locator="Navigation-Footer">
      <div className={classes.startSection}>{startSection}</div>

      <div className={classes.middleSection}>{middleSection}</div>

      <div className={classes.endSection}>{endSection}</div>
    </div>
  );
};

BaseNavigationFooter.LinkSectionColumn = BaseNavigationFooterLinkSectionColumn;
