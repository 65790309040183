/* eslint-disable react/no-danger */
import React from 'react';

import { Helmet } from 'react-helmet-async';
import { makeStyles } from 'tss-react/mui';

interface Props {
  src: string;
}

const useStyles = makeStyles()({
  iframe: {
    width: 0,
    border: 'none',
    minWidth: '100% !important',
  },
});

export const Datawrapper: React.FC<Props> = ({ src }) => {
  const { classes } = useStyles();

  return (
    <React.Fragment>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html:
              '!function(){"use strict";window.addEventListener("message",(function(a){if(void 0!==a.data["datawrapper-height"]){var e=document.querySelectorAll("iframe");for(var t in a.data["datawrapper-height"])for(var r=0;r<e.length;r++)if(e[r].contentWindow===a.source){var i=a.data["datawrapper-height"][t]+"px";e[r].style.height=i}}}))}();',
          }}
        />
      </Helmet>
      <iframe
        title="Price index"
        aria-label="Price index"
        src={src}
        frameBorder="0"
        data-external="1"
        className={classes.iframe}
        data-testid="Datawrapper"
        height={800}
      />
    </React.Fragment>
  );
};
