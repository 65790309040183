import { composeWithDevTools } from '@redux-devtools/extension';
import { createStore, applyMiddleware, DeepPartial } from 'redux';

import { Config } from 'ha/config';

import { AppServices } from 'ha/services/getAppServices';
import { GlobalState } from 'ha/types/store';

import { createAppMiddlewares } from './createAppMiddlewares';
import { createAppReducer } from './createAppReducer';
import { MyReduxStore } from './types';

export function createAppStore({
  config,
  initialState,
  services,
}: {
  config: Config;
  initialState: DeepPartial<GlobalState>;
  services: AppServices;
}): MyReduxStore {
  const middlewares = createAppMiddlewares({
    debug: config.debug,
    services,
  });
  const enhancer = applyMiddleware(...middlewares);
  const store = createStore(
    createAppReducer(),
    initialState,
    process.browser ? composeWithDevTools(enhancer) : enhancer,
  ) as unknown as MyReduxStore;

  store.asyncReducers = {};

  store.injectAsyncReducer = (name, asyncReducer) => {
    if (store.asyncReducers[name]) {
      return;
    }

    store.asyncReducers[name] = asyncReducer;

    store.replaceReducer(createAppReducer(store.asyncReducers));
  };

  return store;
}
