import { Page } from '../Page';
import { ValuePropositionDataTypes } from '../ValuePropositions/ValuePropositionDataTypes';

import { HowToPageType } from './HowTo';

export enum FeaturePageType {
  Advertiser = 'advertiser',
  CommonLight = 'commonLight',
  CommonDark = 'commonDark',
  Tenant = 'tenant',
}

export interface FeaturePageData {
  ctaLinkText: string;
  ctaUrl: string;
  description: string;
  metaDescription: string;
  metaTitle: string;
  pageType: FeaturePageType;
  parentType: HowToPageType;
  title: string;
  subTitle?: string;
  valuePropositions: ValuePropositionDataTypes[];
  videoUrl?: string;
}

export type FeaturePage = Page<FeaturePageData>;
