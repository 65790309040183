/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

type Teams = 'payments';

interface Options {
  /** @deprecated This data is not searchable - consider using tags or chaining errors with `new Error("...", { cause: original })`  */
  metaData?: Record<string, any>;
  severity?: 'info' | 'warning' | 'error';
  tags?: Record<string, any> & { team?: Teams };
}

export type ReportError = Error | string;

function parseErrorMessage(error: ReportError, options?: Options) {
  if (typeof error === 'string') {
    return new Error(error, { cause: options?.metaData?.error });
  }

  // eslint-disable-next-line no-param-reassign
  if (!error.cause) error.cause = options?.metaData?.error;

  return error;
}

export function reportError(e: ReportError, options?: Options) {
  try {
    const error = parseErrorMessage(e, options);

    Sentry.captureException(error, {
      tags: options?.tags,
      extra: options?.metaData,
      level: options?.severity || 'error',
    });

    if (process.browser) {
      // eslint-disable-next-line no-console
      console.error('The following error was manually captured', error);
    }
  } catch (error) {
    // just a precaution - can be removed in case we don't see any errors appear
    Sentry.captureException(new Error('Failed to capture error'), {
      extra: { original: e, ...options?.metaData },
      level: options?.severity || 'error',
    });
  }
}
