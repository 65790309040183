import { Language } from '../../../lib/types';

// paths for chinese pages are commented due to absent translations

export const globalReach = {
  [Language.en]: '/global-audience-reach',
  [Language.fr]: '/audience-mondiale',
  [Language.de]: '/weltweite-reichweite',
  [Language.es]: '/visibilidad-global',
  [Language.it]: '/visibilita-globale',
  [Language.nl]: '/wereldwijd-publiek-globaal-bereik',
  [Language.pt]: '/alcance-do-publico-mundial',
  // [Language.zh]: '/覆盖全球观众',
  [Language.zh]: '/global-audience-reach',
  [Language.uk]: '/залучення-аудиторії',
  [Language.ru]: '/глобальный-охват-аудитории',
  [Language.pl]: '/globalny-zasieg-odbiorcow',
  [Language.sv]: '/global-publik-rackvidd',
  [Language.ro]: '/atingerea-audientei-globale',
};

export const easyPropertyManagement = {
  [Language.en]: '/easy-property-management',
  [Language.fr]: '/gestion-immobiliere-simplifiee',
  [Language.de]: '/einfache-immobilienverwaltung',
  [Language.es]: '/gestiona-tus-propiedades-facilmente',
  [Language.it]: '/gestisci-facilmente-le-tue-proprietà',
  [Language.nl]: '/eenvoudig-woonruimte-adverteren',
  [Language.pt]: '/gestao-facil-da-propriedade',
  // [Language.zh]: '/简易物业管理',
  [Language.zh]: '/easy-property-management',
  [Language.uk]: '/просте-управління-житлом',
  [Language.ru]: '/простое-управление-недвижимостью',
  [Language.pl]: '/latwe-zarzadzanie-nieruchomosciami',
  [Language.sv]: '/enkel-fastighetsforvaltning',
  [Language.ro]: '/gestionarea-usoara-a-proprietatii',
};

export const integrations = {
  [Language.en]: '/integrations',
  [Language.fr]: '/integrations',
  [Language.de]: '/integrationen',
  [Language.es]: '/integraciones',
  [Language.it]: '/integrazioni',
  [Language.nl]: '/api-integratie',
  [Language.pt]: '/integracoes',
  // [Language.zh]: '/集成',
  [Language.zh]: '/integrations',
  [Language.uk]: '/інтеграції',
  [Language.ru]: '/интеграции',
  [Language.pl]: '/integracje',
  [Language.sv]: '/integrationer',
  [Language.ro]: '/integrari',
};

export const securePayments = {
  [Language.en]: '/secure-payments',
  [Language.fr]: '/paiements-securise',
  [Language.de]: '/sichere-bezahlung',
  [Language.es]: '/pagos-seguros',
  [Language.it]: '/pagamenti-sicuri',
  [Language.nl]: '/veilige-huurbetalingen',
  [Language.pt]: '/pagamentos-seguros',
  // [Language.zh]: '/安全支付',
  [Language.zh]: '/secure-payments',
  [Language.uk]: '/безпечні-платежі',
  [Language.ru]: '/безопасные-платежи',
  [Language.pl]: '/bezpieczne-platnosci',
  [Language.sv]: '/sakra-betalningar',
  [Language.ro]: '/plati-sigure',
};

export const superiorMatching = {
  [Language.en]: '/superior-matching',
  [Language.fr]: '/trouvez-le-locataire-ideal',
  [Language.de]: '/der-perfekte-mieter',
  [Language.es]: '/encuentra-al-inquilino-perfecto',
  [Language.it]: '/trova-l-inquilino-ideale',
  [Language.nl]: '/de-beste-match-voor-jou',
  [Language.pt]: '/match-de-inquilinos',
  // [Language.zh]: '/优越的匹配',
  [Language.zh]: '/superior-matching',
  [Language.uk]: '/точний-підбір',
  [Language.ru]: '/точный-подбор',
  [Language.pl]: '/idealne-dopasowanie',
  [Language.sv]: '/overlagsen-matchning',
  [Language.ro]: '/potrivire-superioara',
};

export const smartSearch = {
  [Language.en]: '/smart-search',
  [Language.nl]: '/slim-zoeken',
  [Language.fr]: '/recherche-intelligente',
  [Language.de]: '/intelligente-suche',
  [Language.it]: '/ricerca-intelligente',
  [Language.es]: '/busqueda-inteligente',
  [Language.pt]: '/busca-inteligente',
  [Language.uk]: '/розумний-пошук',
  [Language.ru]: '/интеллектуальный-поиск',
  [Language.pl]: '/inteligentne-wyszukiwanie',
  [Language.sv]: '/smart-sokning',
  [Language.ro]: '/cautare-inteligenta',
  // [Language.zh]: '/智能搜索',
  [Language.zh]: '/smart-search',
};

export const onlineViewing = {
  [Language.en]: '/online-viewing',
  [Language.nl]: '/online-bezichtigen',
  [Language.fr]: '/visites-en-ligne',
  [Language.de]: '/online-besichtigungen',
  [Language.it]: '/visite-online',
  [Language.es]: '/visitas-online',
  [Language.pt]: '/visitas-online',
  [Language.uk]: '/онлайн-перегляд',
  [Language.ru]: '/онлайн-просмотр',
  [Language.pl]: '/ogladanie-online',
  [Language.sv]: '/visning-pa-natet',
  [Language.ro]: '/vizualizare-online',
  // [Language.zh]: '/在线观看',
  [Language.zh]: '/online-viewing',
};

export const directMessaging = {
  [Language.en]: '/direct-messaging',
  [Language.nl]: '/chatten-met-verhuurders',
  [Language.fr]: '/messagerie-instantanee',
  [Language.de]: '/direktnachrichten',
  [Language.it]: '/messaggistica-istantanea',
  [Language.es]: '/mensajes-en-tiempo-real',
  [Language.pt]: '/mensagem-direta',
  [Language.uk]: '/обмін-повідомленнями',
  [Language.ru]: '/прямые-сообщения',
  [Language.pl]: '/bezposrednie-przesylanie-wiadomosci',
  [Language.sv]: '/direktmeddelanden',
  [Language.ro]: '/mesagerie-directa',
  // [Language.zh]: '/直接发送消息',
  [Language.zh]: '/direct-messaging',
};

export const tenantPayments = {
  [Language.en]: '/online-rent-payment',
  [Language.fr]: '/paiement-locatif-en-ligne',
  [Language.de]: '/online-mietzahlung',
  [Language.es]: '/pago-alquiler-online',
  [Language.it]: '/pagamento-affitto-online',
  [Language.nl]: '/online-huur-betalen',
  [Language.pt]: '/pagamento-de-aluguel-online',
  // [Language.zh]: '/在线支付租金',
  [Language.zh]: '/online-rent-payment',
  [Language.uk]: '/онлайн-оплата-оренди',
  [Language.ru]: '/онлайн-оплата-аренды',
  [Language.pl]: '/platnosc-czynszu-online',
  [Language.sv]: '/hyresbetalning-online',
  [Language.ro]: '/plata-chiriei-online',
};

export const landlordPayments = {
  [Language.en]: '/online-rent-collection',
  [Language.fr]: '/loyers-en-ligne',
  [Language.de]: '/online-mieteinzuege',
  [Language.es]: '/cobro-alquiler-online',
  [Language.it]: '/riscuoti-gli-affitti-online',
  [Language.nl]: '/online-huurinkomsten-beheren',
  [Language.pt]: '/cobranca-de-aluguel-online',
  // [Language.zh]: '/网上收租',
  [Language.zh]: '/online-rent-collection',
  [Language.uk]: '/збір-орендної-плати-онлайн',
  [Language.ru]: '/получайте-аренду-онлайн',
  [Language.pl]: '/pobieranie-czynszu-online',
  [Language.sv]: '/hyresuppbord-online',
  [Language.ro]: '/incasarea-chiriei-online',
};
