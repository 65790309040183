import { HAMobileAppMetadata } from './types';

declare global {
  interface Window {
    haMobileAppMetadata: import('./types').HAMobileAppMetadata | undefined;
    isHaMobileApp: boolean | undefined;
    isTrackingPermissionGranted: boolean | undefined;
    OneTrust: {
      AllowAll: () => void;
      RejectAll: () => void;
    };
  }
}

export const isHaMobileApp = (): boolean => {
  try {
    return Boolean(typeof window !== 'undefined' && window.isHaMobileApp);
  } catch {
    return false;
  }
};

export const getHaMobileAppMetadata = (): HAMobileAppMetadata | undefined => {
  if (!isHaMobileApp()) return undefined;
  if (!window.haMobileAppMetadata) return undefined;

  return {
    ...window.haMobileAppMetadata,
    osType:
      window.haMobileAppMetadata.brand.toLowerCase() === 'apple'
        ? 'iOS'
        : 'Android',
  } as HAMobileAppMetadata;
};

export const getAppVersion = (): string | undefined => {
  const metadata = getHaMobileAppMetadata();
  if (metadata?.appVersion) {
    return `v${metadata.appVersion}${
      metadata.appBuild ? ` (${metadata.appBuild})` : ''
    }`;
  }
  return undefined;
};

export const isTrackingPermissionGranted = (): boolean => {
  if (!isHaMobileApp()) return false;
  return window.isTrackingPermissionGranted || false;
};

const CHECK_INTERVAL = 500; // ms
const TIMEOUT = 5000; // ms
const waitForOneTrust = () => {
  return new Promise<typeof window.OneTrust>((resolve, reject) => {
    let timeoutId: NodeJS.Timeout;

    const intervalId = setInterval(() => {
      if (window.OneTrust) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        resolve(window.OneTrust);
      }
    }, CHECK_INTERVAL);

    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      reject(new Error('OneTrust not found within timeout'));
    }, TIMEOUT); // Adjust the timeout as needed
  });
};

export const consentToCookiePopUpFromHaMobileApp = async () => {
  try {
    await waitForOneTrust();
    if (isTrackingPermissionGranted()) {
      window.OneTrust.AllowAll();
      return;
    }
    window.OneTrust.RejectAll();
  } catch {
    // do nothing
  }
};
