import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

export const LoadableSomethingWentWrongPage = loadable(() =>
  autoRetry(
    () =>
      import(
        /* webpackChunkName: "SomethingWentWrongPage" */ './SomethingWentWrongPage'
      ),
  ),
);
