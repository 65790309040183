import { ImageOptions } from 'ha/modules/Contentful/components/ContentfulImg/types';

import { buildContentfulAssetUrl } from '../buildContentfulAssetUrl';

export function buildContentfulAssetSrcSet(
  url: string,
  widths: number[],
  params: ImageOptions = {},
) {
  return widths
    .map(
      width =>
        `${buildContentfulAssetUrl(url, { ...params, width })} ${width}w`,
    )
    .join(',');
}
