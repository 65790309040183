import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { IconButton, Link, Typography } from '@hbf/dsl/core';
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from '@hbf/icons/brand-bold';

import { useIntl } from 'ha/i18n';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

export const NO_FOLLOW_REL = 'nofollow noopener noreferrer';

const useStyles = makeStyles()(theme => ({
  linkSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  linkSectionLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(5),
  },
  socialContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  socialIcon: {
    color: theme.palette.secondary.main,
  },
}));

const SocialLink = ({
  socialVendor,
  href,
  children,
}: React.PropsWithChildren<{ socialVendor: string; href: string }>) => {
  const { classes } = useStyles();

  return (
    <Track name="Clicked Social icon" type="icon" socialvendor={socialVendor}>
      <IconButton
        component="a"
        className={classes.socialIcon}
        href={href}
        target="_blank"
        rel={NO_FOLLOW_REL}
        title={socialVendor}
      >
        {children}
      </IconButton>
    </Track>
  );
};

export const SocialLinks = () => {
  const { urlResolver } = useIntl();

  return (
    <React.Fragment>
      <SocialLink socialVendor="facebook" href={urlResolver.getFacebookUrl()}>
        <Facebook />
      </SocialLink>
      <SocialLink socialVendor="twitter" href={urlResolver.getTwitterUrl()}>
        <Twitter />
      </SocialLink>
      <SocialLink socialVendor="linkedin" href={urlResolver.getLinkedinUrl()}>
        <Linkedin />
      </SocialLink>
      <SocialLink socialVendor="youtube" href={urlResolver.getYoutubeUrl()}>
        <Youtube />
      </SocialLink>
      <SocialLink socialVendor="instagram" href={urlResolver.getInstagramUrl()}>
        <Instagram />
      </SocialLink>
    </React.Fragment>
  );
};

interface FooterLinkProps {
  name: string;
}

export const FooterLink = ({
  name,
  children,
  ...rest
}: Omit<React.ComponentProps<typeof Link>, 'size'> & FooterLinkProps) => {
  return (
    <Track name="Clicked Footer Link" type="link" link_name={name}>
      <Link size="sm" {...rest}>
        {children}
      </Link>
    </Track>
  );
};

interface Props {
  title: React.ReactNode;
}

export const NavigationFooterLinks = ({
  title,
  children,
}: React.PropsWithChildren<Props>) => {
  const { classes } = useStyles();

  const isTitleString = typeof title === 'string';

  return (
    <div className={classes.linkSectionContainer}>
      {isTitleString ? (
        <Typography variant="body/sm-semibold">{title}</Typography>
      ) : (
        title
      )}

      <div className={classes.linkSectionLinks}>{children}</div>
    </div>
  );
};

NavigationFooterLinks.Link = FooterLink;
