import { DeepPartial } from 'redux';

import { Language } from '@ha/intl';

import { initialState as initialAuthLogicState } from 'ha/modules/AuthLogic';
import { GlobalState } from 'ha/types/store';

export function getInitialState({
  preloadedState = {},
  language,
}: {
  preloadedState: DeepPartial<GlobalState>;
  language?: Language;
}): DeepPartial<GlobalState> {
  const alerts = preloadedState.alerts || [];

  return {
    ...preloadedState,
    authLogic: {
      ...initialAuthLogicState,
      ...preloadedState.authLogic,
      language,
    },
    languageSwitcher: {
      ...preloadedState.languageSwitcher,
      currentLanguage: {
        code: language,
      },
    },
    alerts,
  };
}
