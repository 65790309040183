import React from 'react';

import { Drawer } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { isHaMobileApp } from '@ha/core/MobileApp';
import { IconButton } from '@hbf/dsl/core';
import { X } from '@hbf/icons/brand-bold';

import { useIntl } from 'ha/i18n';

import { NavigationLogo } from '../NavigationLogo/NavigationLogo';

interface NavigationDrawerProps {
  open: boolean;
  onClose: () => void;
  'data-test-locator'?: string;
}

const useStyles = makeStyles()(theme => ({
  drawerLayout: {
    width: `min(300px, 100vw)`,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      width: `min(400px, 100vw)`,
    },
  },
  drawerHeader: {
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
    borderBottom: `1px solid ${theme.palette.mono.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    gap: theme.spacing(5),
  },
}));

const NavigationDrawerBlock = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.block, className)}>{children}</div>;
};

const NavLogo = ({
  'data-test-locator': dataTestLocator,
}: Pick<NavigationDrawerProps, 'data-test-locator'>) => {
  const navLogo = <NavigationLogo size="full" />;

  const { urlResolver, T } = useIntl();

  if (isHaMobileApp()) {
    return (
      <a
        href={urlResolver.getHomeUrl({ explicitNoRedirect: true })}
        data-test-locator={`${dataTestLocator}/LogoHomePageLink`}
        aria-label={T('Home')}
      >
        {navLogo}
      </a>
    );
  }

  return navLogo;
};

export const NavigationDrawer = ({
  open,
  onClose,
  children,
  'data-test-locator': dataTestLocator,
}: React.PropsWithChildren<NavigationDrawerProps>) => {
  const { classes } = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      data-test-locator={dataTestLocator}
    >
      <div className={classes.drawerLayout}>
        <div className={classes.drawerHeader}>
          <NavLogo />
          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </div>

        {children}
      </div>
    </Drawer>
  );
};

NavigationDrawer.Block = NavigationDrawerBlock;
