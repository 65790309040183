import { RequestError } from '@ha/api/v2/errors';
import type { Query } from '@ha/api/v2/getUser';
import type { Query as QueryUserInformation } from '@ha/api/v2/getUserInformation';
import type { Logger } from '@hbf/log';

import { Thunk } from 'ha/myredux/types';

import { AuthLogicActions } from './actions';

export const fetchUser: (logger?: Logger, referrer?: string) => Thunk =
  (logger?: Logger, referrer?: string) => (dispatch, _, services) => {
    const userQuery: Query = {};
    const userInformationQuery: QueryUserInformation = {
      expand:
        'impersonation,advertiserBookingsCount,savedPaymentMethodsCount,listingsCount,subscriptionFeatures,freeBookingsUsage,partner',
    };

    if (referrer?.includes('id.housinganywhere.com')) {
      userQuery.initial = true;
      userInformationQuery.initial = true;
    }

    return Promise.all([
      services.apiV2.getUser('me', userQuery),
      services.apiV2.getUserInformation('me', userInformationQuery),
    ])
      .then(
        ([
          { data: user, status: statusUserMe },
          { data: userInformation, status: statusUserMeInformation },
        ]) => {
          logger?.info({
            message: 'fetch_user',
            referrer,
            status: statusUserMe,
            statusUserMeInformation,
          });

          dispatch(
            AuthLogicActions.initUser({
              user,
              userInformation,
            }),
          );
        },
      )
      .catch((error: RequestError) => {
        logger?.info({
          message: 'fetch_user',
          referrer,
          status: error?.status,
          error,
        });
      });
  };
