import React from 'react';

import remark from 'remark';
import reactRenderer from 'remark-react';
import remarkSlug from 'remark-slug';

export interface Props {
  content?: string;
  components?: Record<string, React.FC>;
  className?: string;
  parsers?: Array<() => void>;
  'data-test-locator'?: string;
}

const Markdown: React.FC<Props> = ({
  content,
  components = {},
  parsers = [],
  className,
  ...props
}) => {
  const children = React.useMemo(() => {
    if (!content) {
      return null;
    }

    return remark()
      .use(remarkSlug)
      .use(parsers)
      .use(reactRenderer, {
        sanitize: { clobberPrefix: '' },
        toHAST: { allowDangerousHTML: true },
        remarkReactComponents: components,
      })
      .processSync(content).contents;
  }, [components, content, parsers]);

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};
export { Markdown };
