import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/legacy';

import { ContentfulImg } from 'ha/modules/Contentful/components/ContentfulImg';

const useStyles = makeStyles()({
  container: {
    display: 'block',
    padding: '0.7rem 0',
  },
});

interface Props {
  title?: string;
  src: string;
  alt: string;
}

export const MarkdownImage: React.FC<Props> = ({ title = '', src, alt }) => {
  const { classes } = useStyles();

  return (
    <span className={classes.container}>
      <ContentfulImg src={src} alt={alt} />
      {title && (
        <Typography.Paragraph component="span" color="muted">
          {title}
        </Typography.Paragraph>
      )}
    </span>
  );
};
