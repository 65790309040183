import { AssetEntry } from '@ha/contentful';
import { Language } from '@ha/intl';

import { DEFAULT_IMAGE_ALT } from './constants';

export const getContentfulImageAlt = ({
  imageAsset,
  lang,
}: {
  imageAsset: AssetEntry | undefined;
  lang: Language;
}) => {
  if (!imageAsset) {
    return DEFAULT_IMAGE_ALT;
  }

  const { description, title } = imageAsset.fields;

  // Fallback to English if the localized field is not available.
  // English title is always available in Contentful
  return (
    description?.[lang] ||
    description?.en ||
    title?.[lang] ||
    (title?.en as string)
  );
};
