import React from 'react';

import { useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { AssetEntry } from '@ha/contentful';
import { Breakpoint } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';
import noPhotoImgUrl from 'ha/img-src/static/nophotos.png';

import { Props as ImgProps, Img } from 'ha/ui/Img';

import { COMMON_SCREEN_WIDTHS } from './constants';
import {
  buildContentfulAssetSrcSet,
  buildContentfulAssetUrl,
  getContentfulImageAlt,
} from './utils';

const useStyles = makeStyles()({
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});

interface Props {
  quality?: number;
  sizes?: { [key in Breakpoint | 'default']?: string };
  pictureProps?: React.HTMLAttributes<HTMLPictureElement>;
  format?: string;
  // allow extra widths in the srcSet
  widths?: number[];
  src?: string;
  imageAsset?: AssetEntry | undefined;
}

/**
 * ContentfulImg component renders an image with support for Contentful assets.
 * It uses the `Img` component to display the image and provides support for responsive images
 * using the `picture` element and `source` elements for different formats.
 */
const ContentfulImg: React.FC<Omit<ImgProps, 'sizes' | 'src'> & Props> = ({
  src,
  quality = 70,
  sizes = {},
  widths = [],
  format = 'jpg',
  pictureProps,
  alt,
  imageAsset,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const { breakpoints } = useTheme();
  const { lang } = useIntl();

  const altText = alt ?? getContentfulImageAlt({ imageAsset, lang });

  const imageSrc = src || imageAsset?.fields?.url || noPhotoImgUrl;

  const defaultSrc = buildContentfulAssetUrl(imageSrc, {
    format,
    quality,
  });
  // if the url didn't change, it's not customizable with Contentful query params
  const isCustomizable = defaultSrc !== imageSrc;

  const currentBreakpoints = breakpoints.keys.filter(
    breakpoint => sizes[breakpoint],
  );
  const queries = currentBreakpoints.map((breakpoint, index) => {
    const minWidthQuery = `(min-width:${breakpoints.values[breakpoint]}px)`;
    const nextBreakpoint = currentBreakpoints[index + 1];
    const mediaQuery = nextBreakpoint
      ? `${minWidthQuery} and (max-width:${breakpoints.values[nextBreakpoint]}px)`
      : minWidthQuery;

    return `${mediaQuery} ${sizes[breakpoint]}`;
  });

  const sizesAttribute = [...queries, '100vw'].join(', ');

  return (
    <picture {...pictureProps}>
      {isCustomizable && (
        <source
          type="image/webp"
          sizes={sizesAttribute}
          srcSet={buildContentfulAssetSrcSet(
            imageSrc,
            [...new Set([...widths, ...COMMON_SCREEN_WIDTHS])],
            {
              format: 'webp',
              quality,
            },
          )}
        />
      )}
      <Img
        role="presentation"
        src={defaultSrc}
        sizes={sizesAttribute}
        srcSet={
          isCustomizable
            ? buildContentfulAssetSrcSet(
                imageSrc,
                [...widths, ...COMMON_SCREEN_WIDTHS],
                {
                  format,
                  quality,
                },
              )
            : undefined
        }
        className={cx(classes.image, rest.className)}
        alt={altText}
        {...rest}
      />
    </picture>
  );
};

export { ContentfulImg };
