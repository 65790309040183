import loadable from '@loadable/component';

import { autoRetry } from 'ha/utils/loadable';

export const LoadableAdvertiserQuickActionsMinimized = loadable(() =>
  autoRetry(
    () =>
      import(
        /* webpackChunkName: "AdvertiserQuickActionsMinimized" */ './AdvertiserQuickActionsMinimized'
      ),
  ),
);
