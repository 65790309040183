import { createMemoryRouter } from 'react-router-dom';

import { ThunkMiddleware } from 'redux-thunk';

export function queryParamsMiddleware(
  options: {
    metaKey?: string;
    routerRef?: { current: ReturnType<typeof createMemoryRouter> | null };
  } = {},
): ThunkMiddleware {
  const metaKey = options.metaKey || 'queryParams';

  return (/* store */) => next => action => {
    const router = options.routerRef?.current;
    const querySpec = action?.meta && action.meta[metaKey];

    if (querySpec && !action?.meta.skipQueryUpdate && router) {
      const searchParams = new URLSearchParams(
        action.meta.mergeQueryParams ? router.state.location.search : undefined,
      );

      Object.entries(querySpec).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          searchParams.delete(key);
          value.forEach(arrayValue => {
            searchParams.append(key, arrayValue);
          });
          return;
        }

        if (value) {
          searchParams.set(key, value as string);
          return;
        }

        searchParams.delete(key);
      });

      const search = searchParams.toString();

      router.navigate(
        {
          pathname: action.meta.pathname || router.state.location.pathname,
          search: search ? `?${search}` : '',
        },
        {
          replace: Boolean(action.meta.replaceQueryParams),
        },
      );
    }
    next(action);
  };
}
